import "core-js/modules/es.string.trim.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-spin", {
    attrs: {
      spinning: _vm.loading
    }
  }, [_c("div", {
    staticClass: "constainer"
  }, [_c("div", {
    staticClass: "constainer-header"
  }, [_c("a-form", {
    attrs: {
      labelCol: {
        style: "width: 85px"
      },
      form: _vm.editorForm
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "角色名称"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["name", {
        rules: [{
          required: true,
          message: "请输入角色名称!"
        }]
      }],
      expression: "[\n                            'name',\n                            {\n                                rules: [\n                                    { required: true, message: '请输入角色名称!' },\n                                ],\n                            },\n                        ]"
    }],
    attrs: {
      autoComplete: "off",
      size: "large",
      placeholder: "请输入",
      maxlength: "50"
    },
    on: {
      change: function change(e) {
        e.target.value = e.target.value.trim();
        return e;
      }
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "权限选择"
    }
  }, [_c("a-directory-tree", {
    attrs: {
      checkable: "",
      treeData: _vm.menuList,
      selectedKeys: _vm.selectedKeys,
      expandedKeys: _vm.expandedKeys,
      autoExpandParent: _vm.autoExpandParent,
      replaceFields: {
        children: "children",
        title: "name",
        key: "id"
      }
    },
    on: {
      expand: _vm.onExpand,
      select: _vm.onSelect,
      check: _vm.onCheck
    },
    model: {
      value: _vm.checkedKeys,
      callback: function callback($$v) {
        _vm.checkedKeys = $$v;
      },
      expression: "checkedKeys"
    }
  })], 1), _c("a-form-item", {
    staticStyle: {
      display: "flex",
      "justify-content": "space-between"
    }
  }, [_c("a-button", {
    staticClass: "btn btn_l",
    staticStyle: {
      background: "#F5F5F5",
      border: "0"
    },
    attrs: {
      size: "large"
    },
    on: {
      click: _vm.cancle
    }
  }, [_vm._v(_vm._s("取消"))]), _c("a-button", {
    staticClass: "btn btn_r",
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      size: "large",
      type: "primary"
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v(_vm._s(_vm.editId ? "保存" : "创建"))])], 1)], 1)], 1)])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };