import "core-js/modules/es.number.constructor.js";
import { mapState, mapGetters, mapActions } from "vuex";
import { SysAccountApi } from '@/api';
export default {
  name: "UpdateStatus",
  components: {},
  props: {
    updateStatus: String | Number,
    editId: String | Number
  },
  data: function data() {
    return {};
  },
  computed: {},
  created: function created() {},
  methods: {
    cancle: function cancle() {
      this.$emit('handleAddCancle');
    },
    submit: function submit() {
      var _this = this;
      SysAccountApi.updatestatus({
        id: this.editId,
        status: this.updateStatus
      }).then(function (res) {
        _this.toast("操作成功", "success");
        _this.$emit('handleAddSubmit');
      }).catch(function (err) {});
      // this.$emit('handleAddSubmit')
    }
  }
};